import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Carousel } from "react-responsive-carousel"
import styled from "styled-components"
import { Button } from "../../core/Button"
import { Section, SectionWrapper, Title, Text } from "../../core/commonExports"

const reviewData = [
  {
    reviewer: "Zafar Khan",
    reviewerDP: <StaticImage 
      src="../../../assets/images/campaigns/review-imgs/zafar-khan.png"
      alt="anuj-gupta"
      loading="lazy"
    />,
    business: "Restaurant Owner in Bangalore",
    info: "a month ago | 21 Reviews",
    reviewText: <><b>I built my café from the ground up</b> and had seen incidents of fire, electrical short circuits and oil related hazards. When they called me and clarified everything they can cover for a <b>premium lesser than 5K per year</b> I was sold right at that moment. Everything was easy and simple, and I got my insurance <b>policy within a day</b></>,
  },
  {
    reviewer: "Anuj Gupta",
    reviewerDP: <StaticImage 
      src="../../../assets/images/campaigns/review-imgs/anuj-gupta.webp"
      alt="anuj-gupta"
      loading="lazy"
    />,
    business: "Automobile parts trader, Faridabad",
    info: "4 months ago | 2 Reviews | 4 Photos",
    reviewText: "I was looking to insure my business for a long time but was confused about the procedure and coverage. Then this Verak team approached me. Even though the entire purchase was done online, I found the process very transparent and the staff very supportive. I ended up buying it at an affordable price and that too with extra benefits. It was totally worth it!",
  },
  {
    reviewer: "Vishal Ahuja",
    reviewerDP: <StaticImage 
      src="../../../assets/images/campaigns/review-imgs/vishal-ahuja.webp"
      alt="vishal-ahuja"
      loading="lazy"
    />,
    business: "Mobile shop owner, Gwalior",
    info: "a month ago | 3 Reviews",
    reviewText: (
      <>
        I have my mobile shop business in topi bazar Gwalior (MP). Our entire area is full of clothing, shoes, mobile and fast food stores. With such a wide variety of shops, there is even a greater amount of risk involved. What will happen if something accidentally happens in nearby shop. Hence, it’s always better to be protected that’s why I recently bought business insurance policy with Verak and it’s been an amazing experience. Their team is very supportive and continuously follows up. Highly recommended!
        <br /><br />
        Couldn't be happier.
      </>
    ),
  },
]

const ReviewItem = ({reviewData = {}}) => {
  const {info, reviewer, reviewerDP, reviewText, business} = reviewData
  return (
    <ReviewCard>
      <ReviewHeader>
        <ReviewMetaInfo>
          <ReviewerProfilePicture>
            {reviewerDP}
          </ReviewerProfilePicture>
          <section>
            <section className="d-flex">
              <Text fontSize="16px" mfontSize="13px" lineHeight="17px" mlineHeight="15px" fontWeight="500" color="rgba(0, 0, 0, 0.87)" style={{margin: "0", marginRight: "0.25rem"}}>{reviewer}</Text>
              <div style={{width: "78px", height: "14px"}}>
                <StaticImage 
                  src="../../../assets/images/campaigns/review-imgs/google-review-5-stars.webp"
                  alt="5-stars"
                  loading="lazy"
                  placeholder="blurred"
                />
              </div>
            </section>
            <Text fontSize="14px" mfontSize="12px" lineHeight="19px" mlineHeight="15px" color="rgba(0, 0, 0, 0.54)" fontWeight="400" style={{marginTop: '0', display: "flex", textAlign: "left"}}>
              {info}
            </Text>
            <Text fontSize="14px" mfontSize="12px" lineHeight="19px" mlineHeight="15px" color="#2A363D" fontWeight="600" style={{marginTop: '0', display: "flex", textAlign: "left"}}>
              {business}
            </Text>
          </section>
        </ReviewMetaInfo>
        <div style={{width: "24px"}}>
          <StaticImage 
            src="../../../assets/images/campaigns/review-imgs/google.webp"
            alt="google-review"
            loading="lazy"
            placeholder="blurred"
          />
        </div>
      </ReviewHeader>
      <ReviewText>
        {reviewText}
      </ReviewText>
    </ReviewCard>
  )
}

const ReviewsSection = ({
  handleGetQuoteClick, 
  quoteCtaProps={},
  style={},
  desktopStyles={background: "#fff"},
  mobileStyles={background: "#F6F9FF"},
  shopInsurance=false
}) => {
  const {label, uppercaseLabel} = quoteCtaProps
  return (
    <Section desktopStyles={desktopStyles} mobileStyles={mobileStyles} style={style}>
      <SectionWrapper style={{padding: "2rem 0px"}}>
        <Title fontSize="32px" mfontSize="20px" lineHeight="38px" mlineHeight="32px" fontWeight="700" color="#333333" style={{textAlign: "center", marginBottom: "48px"}}>
          Join 5000+ {shopInsurance ? "shop owners" : "businesses"} that trust us to protect them!
        </Title>
        <ReviewsContainer>
          <Carousel centerMode infiniteLoop>
            {reviewData.map(review => <ReviewItem reviewData={review} />)}
          </Carousel>
        </ReviewsContainer>
        <Button label={label || "Get a Quote"} uppercaseLabel={uppercaseLabel || false} type="flatdesign" wide style={{margin: 'auto', marginTop: '2rem'}} onClick={handleGetQuoteClick} />
      </SectionWrapper>
    </Section>
  );
}

export default ReviewsSection

const ReviewsContainer = styled.section`
  width: 100%;
  margin: auto;
  .carousel, .carousel-root {
    width: 100%;
  }
  .slide {
    min-width: 78% !important;
  }
  .slider-wrapper {
    padding: 2rem 5rem;
  }
  .carousel .slider-wrapper.axis-horizontal .slider {
    display: flex;
    gap: 2rem;
    align-items: flex-start;
  }
  .carousel.carousel-slider .control-dots .dot {
    background: #244BCE !important;
  }
  .carousel-status {
    display: none;
  }
  @media screen and (max-width: 768px) {
    .slide {
      min-width: 70% !important;
    }
    .slider-wrapper {
      padding: 2rem 1rem;
    }
  }
`

const ReviewCard = styled.article`
  padding: 1.5rem;
  margin: auto;
  margin-bottom: 1rem;
  width: min(780px, 100%);
  min-height: 310px;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  border-radius: 12px;

  @media screen and (max-width: 768px) {
    padding: 0.5rem;
    borde-radius: 8px;
  }
`

const ReviewHeader = styled.section`
  display: flex;
  justify-content: space-between;
`

const ReviewMetaInfo = styled(ReviewHeader)`
  gap: 0.83rem;

  .d-flex {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: 768px) {
    gap: 0.625rem;
    .d-flex {
      margin-bottom: 0.25rem;
    }
  }
`

const ReviewerProfilePicture = styled.section`
  width: 40px;
`

const ReviewText = styled.section`
  margin-left: 54px;
  margin-right: 27px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #2A363D;
  text-align: left;

  @media screen and (max-width: 768px){
    margin-left: 24px;
    margin-right: 4px;
    font-size: 14px;
  }
`